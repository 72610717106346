/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-chatbot-kit-chat-header {
  background-color: white !important;
  color: #5B6577 !important;
  display: none !important;
  justify-content: center;
  box-shadow: 0px 6px 7px 0px rgba(174, 174, 192, 0.40);
  padding: 30px !important;
  text-align: center;
  font-size: 20px !important;
  font-family: Roboto, sans-serif !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 100%;
  letter-spacing: 0.193px;
  text-transform: capitalize !important;
}

.react-chatbot-kit-chat-bot-message-container {
  position: relative;
}

.react-chatbot-kit-chat-bot-message {
  border-radius: 13px !important;
  background-color:  #F4F4F4 !important;
  box-shadow: 6px 6px 7px 0 rgba(174, 174, 192, 0.40), -6px -6px 7px 0 #FFF;
  color: #5B6577 !important;
  font-size: 18px !important;
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  width: 70% !important;
  margin-left: 90px !important;
  min-height: 60px;
  display: flex;
  align-items: center;
}



.react-chatbot-kit-user-chat-message {
  border-radius: 13px !important;
  background-color:  #F4F4F4 !important;
  box-shadow: 6px 6px 7px 0 rgba(174, 174, 192, 0.40), -6px -6px 7px 0 #FFF;
  color: #5B6577 !important;
  font-size: 20px !important;
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  padding: 7px 20px !important;
  display: flex;
  align-items: center;justify-content: center;
}

button.MuiButtonBase-root {
  margin: 12px auto;
  min-width: 200px;
  border: 0 !important;
}

button.MuiButtonBase-root:hover {
  box-shadow: inset 6px 6px 7px 0 rgba(174, 174, 192, 0.40), inset -6px -6px 7px 0 #fff !important;
}

.react-chatbot-kit-user-avatar, .react-chatbot-kit-user-chat-message-arrow {
  display: none;
}

.MuiAvatar-root.MuiAvatar-circular {
  position: absolute;
  width: 105px !important;
  height: 105px !important;
  z-index: 9;
  top: 0;
  bottom: 0;
  margin: auto;
  box-shadow: 6px 6px 7px 0 rgb(174 174 192 / 78%), -6px -6px 7px 0 #FFF;
}



.react-chatbot-kit-chat-bot-message-arrow {
  display: none;
}

.react-chatbot-kit-chat-input-container {
  position: fixed !important;
  bottom: 15px !important;
}

.react-chatbot-kit-chat-input-form {
  margin: auto;
  width: 85% !important;
  border-radius: 12px;
  background-color: #F4F4F4;
  padding: 10px;
  box-shadow: 6px 6px 7px 0 rgba(174, 174, 192, 0.40), -6px -6px 7px 0 #FFF;
  flex-direction: row-reverse;
}

.react-chatbot-kit-chat-input-form input {
  background: #eeeeee;
  border: 0 !important;
  box-shadow: inset 6px 6px 7px 0 rgba(174, 174, 192, 0.40), inset -6px -6px 7px 0 #fff;
  border-radius: 10px;
  padding: 14px 18px!important;
}

button {
  border-radius: 12px !important;
  background-color: #F4F4F4 !important;
  box-shadow: 6px 6px 7px 0 rgba(174, 174, 192, 0.40), -6px -6px 7px 0 #FFF !important;
  color:#5B6577 !important;
  max-width: 215px;
  text-align: center;
  font-size: 18px !important;
  font-family: Raleway, sans-serif !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 28px !important;
  text-transform: initial !important;
}

.react-chatbot-kit-chat-btn-send {
  background-color: transparent !important;
  box-shadow: 0 0 !important;
  width: 50px !important;
  margin-right: 10px;
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.react-chatbot-kit-chat-btn-send svg {
  fill: #808B9F !important;
  width: 22px;
}


.react-chatbot-kit-chat-container {
  width: 100% !important;
}

.react-chatbot-kit-chat-message-container{
  height: calc(100vh - 170px) !important;
  overflow: auto !important;
}

.react-chatbot-kit-chat-inner-container{
  background-color: #F4F4F4 !important;
  height: 100vh !important;
}

.react-chatbot-kit-chat-bot-message span {
  max-width: 100%;
  overflow: hidden;
  white-space: normal;
  animation: typing 2s steps(50, end);
  white-space: pre-line;
  margin-left: 22px;
}

#ai-con {
  position: fixed;
  top: 39px;
  left: 0;
  width: 180px;
  display: none;
}
#ai-con #ai-video {
  width: 100%;
  z-index: 1;
}
#ai-con .setting {
  cursor: pointer;
  width: 24px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
}
#ai-conf {
  display: none;
  width: 180px;
  height: 150px;
}

/*  ---------------- for mobile ----------------------- */

@media screen and (max-width: 600px) {
  .react-chatbot-kit-chat-bot-message {
    margin-left: 72px !important;
    font-size: 15px !important;
  }
  .react-chatbot-kit-chat-bot-message span {
  margin-left: 0px;
} 
.MuiAvatar-root.MuiAvatar-circular {
  width: 70px !important;
  height: 70px !important;
}
}
