.btns label, .btns button {
    margin: 10px;
}

.btns label {
    margin-top: 35px;
    border-radius: 12px;
    background-color: #F4F4F4 !important;
    box-shadow: 6px 6px 7px 0 rgba(174, 174, 192, 0.40), -6px -6px 7px 0 #FFF;
    color:#5B6577 !important;
    text-align: center;
    font-size: 18px;
    font-family: Raleway, sans-serif !important;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    text-transform: initial;
}

/* ChatMessage.css */
.voir-plus-btn {
    background-color: #3498db; 
    color: #fff; 
    border: none;
    padding: 5px 10px;
    margin-left: 5px; 
    cursor: pointer;
    position: absolute;
    bottom: 0px;
    right: 0;
  }
  
  /* Style au survol du bouton */
  .voir-plus-btn:hover {
    background-color: #2980b9; 
  }
  